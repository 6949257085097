import type { EmptyObjectResponse, IOAuthToken } from '../../core';
import { Http } from '../../core';
import type {
  AuthTokenAttr,
  AuthTokenParams,
  RefreshTokenAttr,
  RefreshTokenParams,
  RevokeTokenAttr,
} from '../interfaces/Authentication';
import routes from '../routes';
import { Observable } from 'rxjs';

export default class Authentication extends Http {
  public getToken(params: AuthTokenAttr): Observable<IOAuthToken> {
    return this.spreeResponse<IOAuthToken>({
      method: 'POST',
      url: routes.oauthTokenPath(),
      body: {
        ...params,
        grant_type: 'password',
      } as AuthTokenParams,
    });
  }

  public refreshToken(params: RefreshTokenAttr): Observable<IOAuthToken> {
    return this.spreeResponse<IOAuthToken>({
      method: 'POST',
      url: routes.oauthTokenPath(),
      tokens: { bearerToken: params.bearer_token },
      body: {
        refresh_token: params.refresh_token,
        grant_type: 'refresh_token',
      } as RefreshTokenParams,
    });
  }

  public revokeToken(params: RevokeTokenAttr): Observable<EmptyObjectResponse> {
    return this.spreeResponse<EmptyObjectResponse>({
      method: 'POST',
      url: routes.oauthRevokePath(),
      tokens: { bearerToken: params.token },
      body: { token: params.token },
    });
  }
}
