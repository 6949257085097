import SpreeSDKError from './SpreeSDKError';
import { HttpResponseBase } from '@angular/common/http';

export default class SpreeError extends SpreeSDKError {
  public serverResponse: HttpResponseBase;

  constructor(serverResponse: HttpResponseBase) {
    super(`Spree returned a HTTP ${serverResponse.status} error code`);
    Object.setPrototypeOf(this, SpreeError.prototype);
    this.name = 'SpreeError';
    this.serverResponse = serverResponse;
  }
}
