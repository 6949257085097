/* eslint-disable @typescript-eslint/no-explicit-any */
import { Http, squashAndPreparePositionalArguments } from '../../core';
import type {
  ListOptions,
  ShowOptions,
  Vendor,
  Vendors as VendorsType,
} from '../interfaces/Vendor';
import routes from '../routes';
import { Observable } from 'rxjs';

/**
 * The multi-vendor marketplace feature is only available via [Vendo](https://www.getvendo.com).
 */
export default class Vendors extends Http {
  /**
   * Returns a list of Vendors in a Spree marketplace.
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const vendors = client.vendors.list({
   *   include: 'products'
   * })
   * ```
   */
  public list(options: ListOptions = {}): Observable<VendorsType> {
    const { token, params } = squashAndPreparePositionalArguments([options], []);

    return this.spreeResponse<VendorsType>({
      method: 'GET',
      url: routes.vendorsPath(),
      tokens: token,
      params: params,
    });
  }

  /**
   * Returns a single Vendor in a Spree marketplace.
   *
   * **Options schema:**
   * ```ts
   * interface options {
   *   id: string
   * }
   * ```
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const vendor = client.vendors.show({ id: '123' })
   * ```
   */
  public show(options: ShowOptions): Observable<Vendor> {
    const { id, token, params } = squashAndPreparePositionalArguments([options], ['id']);

    return this.spreeResponse<Vendor>({
      method: 'GET',
      url: routes.vendorPath(id),
      tokens: token,
      params: params,
    });
  }
}
