import { inject, Injectable, Pipe, PipeTransform } from '@angular/core';
import { StateService } from '../services/state.service';

@Pipe({
  name: 'addLocale',
  standalone: true,
  pure: false,
})
@Injectable({
  providedIn: 'root',
})
export class AddLocalePipe implements PipeTransform {
  state = inject(StateService);

  transform(value: string | null | undefined): string {
    return value
      ? `/${this.state.getActiveLocale()}/${value}`
      : `/${this.state.getActiveLocale()}`;
  }
}
