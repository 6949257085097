/* eslint-disable @typescript-eslint/no-explicit-any */
import { Observable } from 'rxjs';
import type { IQuery, IToken, NoContentResponse } from '../../core';
import { Http, squashAndPreparePositionalArguments } from '../../core';
import type {
  AccountAddressesResponse,
  AccountAddressParams,
  AccountAddressResponse,
  AccountInfoOptions,
  AddressesListOptions,
  CompletedOrderOptions,
  CompletedOrdersListOptions,
  ConfirmOptions,
  CreateAddressOptions,
  CreateOptions,
  CreditCardsListOptions,
  DefaultCreditCardOptions,
  ForgotPasswordOptions,
  ForgotPasswordParams,
  IAccount,
  IAccountConfirmation,
  RemoveAddressOptions,
  RemoveCreditCardOptions,
  ResetPasswordOptions,
  ResetPasswordParams,
  ShowAddressOptions,
  UpdateAddressOptions,
  UpdateOptions,
} from '../interfaces/Account';
import type { ICreditCard, ICreditCards } from '../interfaces/CreditCard';
import type { IOrder, IOrders } from '../interfaces/Order';
import routes from '../routes';

export default class Account extends Http {
  /**
   * Creates new account and returns its attributes. See [api docs](https://api.spreecommerce.org/docs/api-v2/534a12ece987f-create-an-account).
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const response = client.account.create({
   *   user: {
   *     email: 'john@snow.org',
   *     password: 'spree123',
   *     password_confirmation: 'spree123'
   *   }
   * })
   * ```
   */
  public create(options: CreateOptions): Observable<IAccount>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   * Creates new account and returns its attributes.
   */
  public create(params: IQuery): Observable<IAccount>;
  public create(...allArguments: any[]): Observable<IAccount> {
    const [paramsOrOptions = {}] = allArguments;
    const { token, params } = squashAndPreparePositionalArguments([paramsOrOptions], []);

    return this.spreeResponse<IAccount>({
      method: 'POST',
      url: routes.accountPath(),
      tokens: token,
      body: params,
    });
  }

  /**
   * Confirms new account e-mail and returns account registration status. See [reference](https://github.com/spree/spree_auth_devise/blob/db4ccf202f42cdb713931e9915b213ab9c9b2062/config/routes.rb).
   *
   * **Success response schema:**
   * ```ts
   * {
   *   data: {
   *     state: string
   *   }
   * }
   * ```
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const response = client.account.confirm({ confirmation_token: '2xssfC9Hzf8DJXyRZGmB' })
   * ```
   */
  public confirm(option: ConfirmOptions): Observable<IAccountConfirmation>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public confirm(confirmationToken: string): Observable<IAccountConfirmation>;
  public confirm(...allArguments: any[]): Observable<IAccountConfirmation> {
    const [confirmationTokenOrOptions] = allArguments;
    const { confirmation_token, token, params } = squashAndPreparePositionalArguments(
      [
        typeof confirmationTokenOrOptions === 'object'
          ? confirmationTokenOrOptions
          : { confirmation_token: confirmationTokenOrOptions },
      ],
      ['confirmation_token'],
    );

    return this.spreeResponse<IAccountConfirmation>({
      method: 'GET',
      url: routes.accountConfirmPath(confirmation_token),
      tokens: token,
      params: params,
    });
  }

  /**
   * Sends an account recovery link to the provided email address. The link allows resetting the password for the account.
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const response = client.account.forgotPassword({
   *   user: {
   *     email: 'spree@example.com'
   *   }
   * })
   * ```
   */
  public forgotPassword(options: ForgotPasswordOptions): Observable<NoContentResponse>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public forgotPassword(params: ForgotPasswordParams): Observable<NoContentResponse>;
  public forgotPassword(...allArguments: any[]): Observable<NoContentResponse> {
    const [paramsOrOptions] = allArguments;
    const { token, params } = squashAndPreparePositionalArguments([paramsOrOptions], []);

    return this.spreeResponse<NoContentResponse>({
      method: 'POST',
      url: routes.forgotPasswordPath(),
      tokens: token,
      params: params,
    });
  }

  /**
   * Changes the password associated with the account using an account recovery token.
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const response = client.account.resetPassword({
   *   reset_password_token: '7381273269536713689562374856',
   *   user: {
   *     password: '123!@#asdASD',
   *     password_confirmation: '123!@#asdASD'
   *   }
   * })
   * ```
   */
  public resetPassword(options: ResetPasswordOptions): Observable<NoContentResponse>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public resetPassword(
    resetPasswordToken: string,
    params: ResetPasswordParams,
  ): Observable<NoContentResponse>;
  public resetPassword(...allArguments: any[]): Observable<NoContentResponse> {
    const [resetPasswordTokenOrOptions, positionalParams] = allArguments;
    const { reset_password_token, token, params } = squashAndPreparePositionalArguments(
      [
        typeof resetPasswordTokenOrOptions === 'object'
          ? resetPasswordTokenOrOptions
          : { resetPasswordToken: resetPasswordTokenOrOptions },
        positionalParams,
      ],
      ['reset_password_token'],
    );

    return this.spreeResponse<NoContentResponse>({
      method: 'PATCH',
      url: routes.resetPasswordPath(reset_password_token),
      tokens: token,
      params: params,
    });
  }

  /**
   * Updates account and returns its attributes. See [api docs](https://api.spreecommerce.org/docs/api-v2/9f8e112bbb91f-update-an-account).
   *
   * **Required token:** [Bearer token](../pages/tokens.html#bearer-token)
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const response = client.account.update({
   *   bearer_token: '7381273269536713689562374856',
   *   user: {
   *     email: 'john@snow.org',
   *     password: 'new_spree123',
   *     password_confirmation: 'new_spree123'
   *   }
   * })
   * ```
   */
  public update(options: UpdateOptions): Observable<IAccount>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public update(token: IToken, params: IQuery): Observable<IAccount>;
  public update(...allArguments: any[]): Observable<IAccount> {
    const [tokenOrOptions, positionalParams] = allArguments;
    const { token, params } = squashAndPreparePositionalArguments(
      [tokenOrOptions, positionalParams],
      [],
    );

    return this.spreeResponse<IAccount>({
      method: 'PATCH',
      url: routes.accountPath(),
      tokens: token,
      params: params,
    });
  }

  /**
   * Returns current user information. See [api docs](https://api.spreecommerce.org/docs/api-v2/a531029531471-retrieve-an-account).
   *
   * **Required token:** [Bearer token](../pages/tokens.html#bearer-token)
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const response = client.account.accountInfo({ bearer_token: '7381273269536713689562374856' })
   * ```
   */
  public accountInfo(options: AccountInfoOptions): Observable<IAccount>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public accountInfo(token: IToken, params?: IQuery): Observable<IAccount>;
  public accountInfo(...allArguments: any[]): Observable<IAccount> {
    const [tokenOrOptions, positionalParams = {}] = allArguments;
    const { token, params } = squashAndPreparePositionalArguments(
      [tokenOrOptions, positionalParams],
      [],
    );

    return this.spreeResponse<IAccount>({
      method: 'GET',
      url: routes.accountPath(),
      tokens: token,
      params: params,
    });
  }

  /**
   * Returns a list of Credit Cards for the signed in User. See [api docs](https://api.spreecommerce.org/docs/api-v2/eae76f03a90db-list-all-credit-cards).
   *
   * **Required token:** [Bearer token](../pages/tokens.html#bearer-token)
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const response = client.account.creditCardsList({ bearer_token: '7381273269536713689562374856' })
   * ```
   */
  public creditCardsList(options: CreditCardsListOptions): Observable<ICreditCards>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public creditCardsList(token: IToken, params?: IQuery): Observable<ICreditCards>;
  public creditCardsList(...allArguments: any[]): Observable<ICreditCards> {
    const [tokenOrOptions, positionalParams = {}] = allArguments;
    const { token, params } = squashAndPreparePositionalArguments(
      [tokenOrOptions, positionalParams],
      [],
    );

    return this.spreeResponse<ICreditCards>({
      method: 'GET',
      url: routes.accountCreditCardsPath(),
      tokens: token,
      params: params,
    });
  }

  /**
   * Return the User's default Credit Card. See [api docs](https://api.spreecommerce.org/docs/api-v2/1054d9230daf4-retrieve-the-default-credit-card).
   *
   * **Required token:** [Bearer token](../pages/tokens.html#bearer-token)
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const response = client.account.defaultCreditCard({ bearer_token: '7381273269536713689562374856' })
   * ```
   */
  public defaultCreditCard(options: DefaultCreditCardOptions): Observable<ICreditCard>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public defaultCreditCard(token: IToken, params?: IQuery): Observable<ICreditCard>;
  public defaultCreditCard(...allArguments: any[]): Observable<ICreditCard> {
    const [tokenOrOptions, positionalParams = {}] = allArguments;
    const { token, params } = squashAndPreparePositionalArguments(
      [tokenOrOptions, positionalParams],
      [],
    );

    return this.spreeResponse<ICreditCard>({
      method: 'GET',
      url: routes.accountDefaultCreditCardPath(),
      tokens: token,
      params: params,
    });
  }

  /**
   * Remove a User's Credit Card. See [api docs](https://api.spreecommerce.org/docs/api-v2/b3A6MTc1NjU3NDM-remove-a-credit-card).
   *
   * **Required token:** [Bearer token](../pages/tokens.html#bearer-token)
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const response = client.account.removeCreditCard({
   *   bearer_token: '7381273269536713689562374856',
   *   id: '14'
   * })
   * ```
   */
  public removeCreditCard(
    options: RemoveCreditCardOptions,
  ): Observable<NoContentResponse>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public removeCreditCard(
    token: IToken,
    id: string,
    params?: IQuery,
  ): Observable<NoContentResponse>;
  public removeCreditCard(...allArguments: any[]): Observable<NoContentResponse> {
    const [tokenOrOptions, positionalId, positionalParams = {}] = allArguments;
    const { id, token, params } = squashAndPreparePositionalArguments(
      [{ id: positionalId }, tokenOrOptions, positionalParams],
      ['id'],
    );

    return this.spreeResponse<NoContentResponse>({
      method: 'DELETE',
      url: routes.accountCreditCardRemovePath(id),
      tokens: token,
      params: params,
    });
  }

  /**
   * Returns Orders placed by the User. Only completed ones. See [api docs](https://api.spreecommerce.org/docs/api-v2/94d319dfe8909-list-all-orders).
   *
   * **Required token:** [Bearer token](../pages/tokens.html#bearer-token)
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const response = client.account.completedOrdersList({
   *   bearer_token: '7381273269536713689562374856'
   * })
   * ```
   */
  public completedOrdersList(options: CompletedOrdersListOptions): Observable<IOrders>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public completedOrdersList(token: IToken, params?: IQuery): Observable<IOrders>;
  public completedOrdersList(...allArguments: any[]): Observable<IOrders> {
    const [tokenOrOptions, positionalParams = {}] = allArguments;
    const { token, params } = squashAndPreparePositionalArguments(
      [tokenOrOptions, positionalParams],
      [],
    );

    return this.spreeResponse<IOrders>({
      method: 'GET',
      url: routes.accountCompletedOrdersPath(),
      tokens: token,
      params: params,
    });
  }

  /**
   * Return the User's completed Order. See [api docs](https://api.spreecommerce.org/docs/api-v2/ab4c5da10fbba-retrieve-an-order).
   *
   * **Required token:** [Bearer token](../pages/tokens.html#bearer-token)
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const response = client.account.completedOrder({
   *   bearer_token: '7381273269536713689562374856',
   *   order_number: 'R653163382'
   * })
   * ```
   */
  public completedOrder(options: CompletedOrderOptions): Observable<IOrder>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public completedOrder(
    token: IToken,
    orderNumber: string,
    params?: IQuery,
  ): Observable<IOrder>;
  public completedOrder(...allArguments: any[]): Observable<IOrder> {
    const [tokenOrOptions, positionalOrderNumber, positionalParams = {}] = allArguments;
    const { order_number, token, params } = squashAndPreparePositionalArguments(
      [{ order_number: positionalOrderNumber }, tokenOrOptions, positionalParams],
      ['order_number'],
    );

    return this.spreeResponse<IOrder>({
      method: 'GET',
      url: routes.accountCompletedOrderPath(order_number),
      tokens: token,
      params: params,
    });
  }

  /**
   * Returns a list of Addresses for the signed in User. See [api docs](https://api.spreecommerce.org/docs/api-v2/c8ebb212f75bf-list-all-addresses).
   *
   * **Required token:** [Bearer token](../pages/tokens.html#bearer-token)
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const response = client.account.addressesList({
   *   bearer_token: '7381273269536713689562374856'
   * })
   * ```
   */
  public addressesList(
    options: AddressesListOptions,
  ): Observable<AccountAddressesResponse>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public addressesList(token: IToken): Observable<AccountAddressesResponse>;
  public addressesList(...allArguments: any[]): Observable<AccountAddressesResponse> {
    const [tokenOrOptions] = allArguments;
    const { token, params } = squashAndPreparePositionalArguments([tokenOrOptions], []);

    return this.spreeResponse<AccountAddressesResponse>({
      method: 'GET',
      url: routes.accountAddressesPath(),
      tokens: token,
      params: params,
    });
  }

  /**
   * Returns a single address for the signed in User.
   *
   * **Required token:** [Bearer token](../pages/tokens.html#bearer-token)
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const response = client.account.showAddress({
   *   bearer_token: '7381273269536713689562374856',
   *   id: '1'
   * })
   * ```
   */
  public showAddress(options: ShowAddressOptions): Observable<AccountAddressResponse>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public showAddress(
    token: IToken,
    addressId: string,
    params?: IQuery,
  ): Observable<AccountAddressResponse>;
  public showAddress(...allArguments: any[]): Observable<AccountAddressResponse> {
    const [tokenOrOptions, positionalId, positionalParams = {}] = allArguments;
    const { id, token, params } = squashAndPreparePositionalArguments(
      [{ id: positionalId }, tokenOrOptions, positionalParams],
      ['id'],
    );

    return this.spreeResponse<AccountAddressResponse>({
      method: 'GET',
      url: routes.accountAddressPath(id),
      tokens: token,
      params: params,
    });
  }

  /**
   * Create a new Address for the signed in User. See [api docs](https://api.spreecommerce.org/docs/api-v2/daacab4666dfc-create-an-address).
   *
   * **Required token:** [Bearer token](../pages/tokens.html#bearer-token)
   *
   * **Options schema:**
   * ```ts
   * interface options {
   *   address: {
   *     firstname: string
   *     lastname: string
   *     address1: string
   *     address2?: string
   *     city: string
   *     phone?: string
   *     zipcode: string
   *     state_name: string // State Abbreviations
   *     country_iso: string // Country ISO (2-chars) or ISO3 (3-chars)
   *     company?: string
   *   }
   * }
   * ```
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const response = client.account.createAddress({
   *   bearer_token: '7381273269536713689562374856',
   *   address: {
   *     firstname: 'John',
   *     lastname: 'Snow',
   *     address1: '7735 Old Georgetown Road',
   *     address2: '2nd Floor',
   *     city: 'Bethesda',
   *     phone: '3014445002',
   *     zipcode: '20814',
   *     state_name: 'MD',
   *     country_iso: 'US',
   *     company: 'Spark'
   *   }
   * })
   * ```
   */
  public createAddress(options: CreateAddressOptions): Observable<AccountAddressResponse>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public createAddress(
    token: IToken,
    params: AccountAddressParams,
  ): Observable<AccountAddressResponse>;
  public createAddress(...allArguments: any[]): Observable<AccountAddressResponse> {
    const [tokenOrOptions, positionalParams] = allArguments;
    const { token, params } = squashAndPreparePositionalArguments(
      [tokenOrOptions, positionalParams],
      [],
    );

    return this.spreeResponse<AccountAddressResponse>({
      method: 'POST',
      url: routes.accountAddressesPath(),
      tokens: token,
      params: params,
    });
  }

  /**
   * Removes selected Address for the signed in User. See [api docs](https://api.spreecommerce.org/docs/api-v2/b3A6MTAwNjA3Njg-remove-an-address).
   *
   * **Required token:** [Bearer token](../pages/tokens.html#bearer-token)
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const response = client.account.removeAddress({
   *   bearer_token: '7381273269536713689562374856',
   *   id: '1'
   * })
   * ```
   */
  public removeAddress(options: RemoveAddressOptions): Observable<NoContentResponse>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public removeAddress(
    token: IToken,
    id: string,
    params?: IQuery,
  ): Observable<NoContentResponse>;
  public removeAddress(...allArguments: any[]): Observable<NoContentResponse> {
    const [tokenOrOptions, positionalId, positionalParams = {}] = allArguments;
    const { id, token, params } = squashAndPreparePositionalArguments(
      [{ id: positionalId }, tokenOrOptions, positionalParams],
      ['id'],
    );

    return this.spreeResponse<NoContentResponse>({
      method: 'DELETE',
      url: routes.accountAddressRemovePath(id),
      tokens: token,
      params: params,
    });
  }

  /**
   * Update selected Address for the signed in User. See [api docs](https://api.spreecommerce.org/docs/api-v2/fbae19a10190d-update-an-address).
   *
   * Required token: Bearer token
   *
   * **Options schema:**
   * ```ts
   * interface options {
   *   id: string
   *   address: {
   *     firstname: string
   *     lastname: string
   *     address1: string
   *     address2?: string
   *     city: string
   *     phone?: string
   *     zipcode: string
   *     state_name: string // State Abbreviations
   *     country_iso: string // Country ISO (2-chars) or ISO3 (3-chars)
   *     company?: string
   *   }
   * }
   * ```
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const response = client.account.updateAddress({
   *   bearer_token: '7381273269536713689562374856',
   *   id: '1',
   *   address: {
   *     firstname: 'John',
   *     lastname: 'Snow',
   *     address1: '7735 Old Georgetown Road',
   *     address2: '2nd Floor',
   *     city: 'Bethesda',
   *     phone: '3014445002',
   *     zipcode: '20814',
   *     state_name: 'MD',
   *     country_iso: 'US',
   *     company: 'Spark'
   *   }
   * })
   * ```
   */
  public updateAddress(options: UpdateAddressOptions): Observable<AccountAddressResponse>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public updateAddress(
    token: IToken,
    addressId: string,
    params: AccountAddressParams,
  ): Observable<AccountAddressResponse>;
  public updateAddress(...allArguments: any[]): Observable<AccountAddressResponse> {
    const [tokenOrOptions, positionalId, positionalParams] = allArguments;
    const { id, token, params } = squashAndPreparePositionalArguments(
      [{ id: positionalId }, tokenOrOptions, positionalParams],
      ['id'],
    );

    return this.spreeResponse<AccountAddressResponse>({
      method: 'PATCH',
      url: routes.accountAddressPath(id),
      tokens: token,
      params: params,
    });
  }
}
