import SpreeError from './SpreeError';
import { HttpResponseBase } from '@angular/common/http';

class BasicSpreeError extends SpreeError {
  public summary: string;

  constructor(serverResponse: HttpResponseBase, errorsSummary: string) {
    super(serverResponse);
    Object.setPrototypeOf(this, BasicSpreeError.prototype);
    this.name = 'BasicSpreeError';
    this.summary = errorsSummary;
  }
}

export default BasicSpreeError;
