/* eslint-disable @typescript-eslint/no-explicit-any */
import type { IQuery, IToken } from '../../core';
import { Http, squashAndPreparePositionalArguments } from '../../core';
import type { IOrder, StatusOptions } from '../interfaces/Order';
import routes from '../routes';
import { Observable } from 'rxjs';

export default class Order extends Http {
  /**
   * Returns a placed Order.
   *
   * **Required token:** [Order token](../pages/tokens.html#order-token)
   *
   * **Options schema:**
   * ```ts
   * interface options {
   *   order_number: string
   * }
   * ```
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const response = client.order.status({
   *   order_token: '7381273269536713689562374856',
   *   order_number: 'R653163382'
   * })
   * ```
   */
  public status(options: StatusOptions): Observable<IOrder>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public status(token: IToken, orderNumber: string, params?: IQuery): Observable<IOrder>;
  public status(...allArguments: any[]): Observable<IOrder> {
    const [tokenOrOptions, positionalOrderNumber, positionalParams = {}] = allArguments;
    const { order_number, token, params } = squashAndPreparePositionalArguments(
      [tokenOrOptions, { order_number: positionalOrderNumber }, positionalParams],
      ['order_number'],
    );

    return this.spreeResponse<IOrder>({
      method: 'GET',
      url: routes.orderStatusPath(order_number),
      tokens: token,
      params: params,
    });
  }
}
