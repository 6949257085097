import { FactoryProvider, InjectionToken, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

export const IS_BROWSER = new InjectionToken<boolean>('is-browser-platform');
export const IS_SERVER = new InjectionToken<boolean>('is-server-platform');

export const IS_BROWSER_PROVIDER: FactoryProvider = {
  provide: IS_BROWSER,
  deps: [PLATFORM_ID],
  useFactory: (pid: object) => {
    return isPlatformBrowser(pid);
  },
};

export const IS_SERVER_PROVIDER: FactoryProvider = {
  provide: IS_SERVER,
  deps: [PLATFORM_ID],
  useFactory: (pid: object) => {
    return isPlatformServer(pid);
  },
};
