export const LocaleMap: { [localeKey: string]: LocaleData } = {
  cs: {
    countryName: 'Česká republika',
    languageName: 'Čeština',
    currency: 'CZK',
  },
  en: {
    countryName: 'Great Britain',
    languageName: 'English',
    currency: 'EUR',
  },
  sk: {
    countryName: 'Slovensko',
    languageName: 'Slovenský',
    currency: 'EUR',
  },
};

export type LocaleData = {
  countryName: string;
  languageName: string;
  currency: string;
};
