/* eslint-disable @typescript-eslint/no-explicit-any */
import { Observable } from 'rxjs';
import type { IQuery } from '../../core';
import { Http, squashAndPreparePositionalArguments } from '../../core';
import type {
  ListOptions,
  Menu,
  Menus as MenusResponse,
  ShowOptions,
} from '../interfaces/Menu';
import routes from '../routes';

export default class Menus extends Http {
  /**
   * Returns a list of Menus. See [api docs](https://api.spreecommerce.org/docs/api-v2/1021e86f10cee-list-all-menus).
   *
   * **Options schema:**
   * ```ts
   * interface options {
   *   locale?: string
   *   filter?: {
   *     location?: string
   *   }
   * }
   * ```
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const response = client.menus.list({
   *   locale: 'fr',
   *   filter: {
   *     location: 'header'
   *   }
   * })
   * ```
   */
  public list(options?: ListOptions): Observable<MenusResponse>;
  public list(...allArguments: any[]): Observable<MenusResponse> {
    const [paramsOrOptions = {}] = allArguments;
    const { token, params } = squashAndPreparePositionalArguments([paramsOrOptions], []);

    return this.spreeResponse<MenusResponse>({
      method: 'GET',
      url: routes.menusPath(),
      tokens: token,
      params: params,
    });
  }

  /**
   * Returns a single Menu. See [api docs](https://api.spreecommerce.org/docs/api-v2/b67d067a42bc5-retrieve-a-menu).
   *
   * **Options schema:**
   * ```ts
   * interface options {
   *   id: string
   * }
   * ```
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const response = client.menus.show({
   *   id: '2'
   * })
   * ```
   */
  public show(options: ShowOptions): Observable<Menu>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public show(id: string, params?: IQuery): Observable<Menu>;
  public show(...allArguments: any[]): Observable<Menu> {
    const [idOrOptions, positionalParams = {}] = allArguments;
    const { id, token, params } = squashAndPreparePositionalArguments(
      [
        typeof idOrOptions === 'object' ? idOrOptions : { id: idOrOptions },
        positionalParams,
      ],
      ['id'],
    );

    return this.spreeResponse<Menu>({
      method: 'GET',
      url: routes.menuPath(id),
      tokens: token,
      params: params,
    });
  }
}
