import {
  Account,
  Authentication,
  Cart,
  Checkout,
  Countries,
  DigitalAssets,
  Menus,
  Order,
  Pages,
  Products,
  Taxons,
  Vendors,
  Wishlists,
} from './endpoints';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from './endpoints/Store';
import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class SpreeService {
  private readonly http = inject(HttpClient);
  private apiUrl = environment.apiUrl;

  account = new Account(this.http, this.apiUrl);
  authentication = new Authentication(this.http, this.apiUrl);
  cart = new Cart(this.http, this.apiUrl);
  checkout = new Checkout(this.http, this.apiUrl);
  countries = new Countries(this.http, this.apiUrl);
  digitalAssets = new DigitalAssets(this.http, this.apiUrl);
  menus = new Menus(this.http, this.apiUrl);
  order = new Order(this.http, this.apiUrl);
  pages = new Pages(this.http, this.apiUrl);
  products = new Products(this.http, this.apiUrl);
  store = new Store(this.http, this.apiUrl);
  taxons = new Taxons(this.http, this.apiUrl);
  vendors = new Vendors(this.http, this.apiUrl);
  wishlists = new Wishlists(this.http, this.apiUrl);
}
