/* eslint-disable @typescript-eslint/no-explicit-any */
import type { IQuery, IToken } from '../../core';
import { Http, squashAndPreparePositionalArguments } from '../../core';
import type {
  AddPayment,
  AddPaymentOptions,
  AddStoreCredit,
  AddStoreCreditOptions,
  AdvanceOptions,
  CompleteOptions,
  CreateStripeSessionOptions,
  NestedAttributes,
  OrderNextOptions,
  OrderUpdate,
  OrderUpdateOptions,
  PaymentMethodsOptions,
  RemoveStoreCreditsOptions,
  SelectShippingMethod,
  SelectShippingMethodOptions,
  ShippingRatesOptions,
} from '../interfaces/Checkout';
import type { IOrder } from '../interfaces/Order';
import type { IPaymentMethods } from '../interfaces/PaymentMethod';
import type { IShippingMethods, ShippingRates } from '../interfaces/ShippingMethod';
import { StripeCheckoutSessionSummary } from '../interfaces/StripeCheckoutSessionSummary';
import routes from '../routes';
import { Observable } from 'rxjs';

export default class Checkout extends Http {
  /**
   * Updates the Checkout. You can run multiple Checkout updates with different data types. See [api docs](https://api.spreecommerce.org/docs/api-v2/b3A6MzE0Mjc1NA-update-checkout).
   *
   * **Required token:** [Bearer token](../pages/tokens.html#bearer-token) or [Order token](../pages/tokens.html#order-token)
   *
   * **Options schema:**
   * ```ts
   * interface options {
   *   order: {
   *     email?: string
   *     special_instructions?: string
   *     bill_address_attributes?: {
   *       firstname: string
   *       lastname: string
   *       address1: string
   *       city: string
   *       phone: string
   *       zipcode: string
   *       state_name: string
   *       country_iso: string
   *     }
   *     ship_address_attributes?: {
   *       firstname: string
   *       lastname: string
   *       address1: string
   *       city: string
   *       phone: string
   *       zipcode: string
   *       state_name: string
   *       country_iso: string
   *     }
   *     shipments_attributes?: [
   *       {
   *         selected_shipping_rate_id: number
   *         id: number
   *       }
   *     ]
   *   }
   * }
   * ```
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * // Logged in user
   * const response = client.checkout.orderUpdate({
   *   bearer_token: '7381273269536713689562374856',
   *   order: {
   *     email: 'john@snow.org'
   *   }
   * })
   *
   * // or guest user
   * const response = client.checkout.orderUpdate({
   *   order_token: '7381273269536713689562374856',
   *   order: {
   *     email: 'john@snow.org'
   *   }
   * })
   * ```
   */
  public orderUpdate(options: OrderUpdateOptions): Observable<IOrder>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public orderUpdate(
    token: IToken,
    params: OrderUpdate | NestedAttributes,
  ): Observable<IOrder>;
  public orderUpdate(...allArguments: any[]): Observable<IOrder> {
    const [tokenOrOptions, positionalParams] = allArguments;

    const body = allArguments[0].body ? allArguments[0].body : {};
    const { token, params } = squashAndPreparePositionalArguments(
      [tokenOrOptions, positionalParams],
      ['body'],
    );

    return this.spreeResponse<IOrder>({
      method: 'PATCH',
      url: routes.checkoutPath(),
      tokens: token,
      params: params,
      responseType: 'json',
      body: body,
    });
  }

  /**
   * Goes to the next Checkout step. See [api docs](https://api.spreecommerce.org/docs/api-v2/b3A6MzE0Mjc1NQ-next-checkout-step).
   *
   * **Required token:** [Bearer token](../pages/tokens.html#bearer-token) or [Order token](../pages/tokens.html#order-token)
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * // Logged in user
   * const response = client.checkout.orderNext({
   *   bearer_token: '7381273269536713689562374856'
   * })
   *
   * // or guest user
   * const response = client.checkout.orderNext({
   *   order_token: '7381273269536713689562374856'
   * })
   * ```
   */
  public orderNext(options: OrderNextOptions): Observable<IOrder>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public orderNext(token: IToken, params?: IQuery): Observable<IOrder>;
  public orderNext(...allArguments: any[]): Observable<IOrder> {
    const [tokenOrOptions, positionalParams = {}] = allArguments;
    const { token, params } = squashAndPreparePositionalArguments(
      [tokenOrOptions, positionalParams],
      [],
    );

    return this.spreeResponse<IOrder>({
      method: 'PATCH',
      url: routes.checkoutNextPath(),
      tokens: token,
      params: params,
    });
  }

  /**
   * Advances Checkout to the furthest Checkout step validation allows, until the Complete step. See [api docs](https://api.spreecommerce.org/docs/api-v2/b3A6MzE0Mjc1Ng-advance-checkout).
   *
   * **Required token:** [Bearer token](../pages/tokens.html#bearer-token) or [Order token](../pages/tokens.html#order-token)
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * // Logged in user
   * const response = client.checkout.advance({
   *   bearer_token: '7381273269536713689562374856'
   * })
   *
   * // or guest user
   * const response = client.checkout.advance({
   *   order_token: '7381273269536713689562374856'
   * })
   * ```
   */
  public advance(options: AdvanceOptions): Observable<IOrder>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public advance(token: IToken, params?: IQuery): Observable<IOrder>;
  public advance(...allArguments: any[]): Observable<IOrder> {
    const [tokenOrOptions, positionalParams = {}] = allArguments;
    const { token, params } = squashAndPreparePositionalArguments(
      [tokenOrOptions, positionalParams],
      [],
    );

    return this.spreeResponse<IOrder>({
      method: 'PATCH',
      url: routes.checkoutAdvancePath(),
      tokens: token,
      params: params,
    });
  }

  /**
   * Completes the Checkout. See [api docs](https://api.spreecommerce.org/docs/api-v2/b3A6MzE0Mjc1Nw-complete-checkout).
   *
   * **Required token:** [Bearer token](../pages/tokens.html#bearer-token) or [Order token](../pages/tokens.html#order-token)
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * // Logged in user
   * const response = client.checkout.complete({
   *   bearer_token: '7381273269536713689562374856'
   * })
   *
   * // or guest user
   * const response = client.checkout.complete({
   *   order_token: '7381273269536713689562374856'
   * })
   * ```
   */
  public complete(options: CompleteOptions): Observable<IOrder>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public complete(token: IToken, params?: IQuery): Observable<IOrder>;
  public complete(...allArguments: any[]): Observable<IOrder> {
    const [tokenOrOptions, positionalParams = {}] = allArguments;
    const { token, params } = squashAndPreparePositionalArguments(
      [tokenOrOptions, positionalParams],
      [],
    );

    return this.spreeResponse<IOrder>({
      method: 'PATCH',
      url: routes.checkoutCompletePath(),
      tokens: token,
      params: params,
    });
  }

  /**
   * Adds Store Credit payments if a user has any. See [api docs](https://api.spreecommerce.org/docs/api-v2/b3A6MzE0Mjc1OA-add-store-credit).
   *
   * **Required token:** [Bearer token](../pages/tokens.html#bearer-token) or [Order token](../pages/tokens.html#order-token)
   *
   * **Options schema:**
   * ```ts
   * interface options {
   *   amount: number
   * }
   * ```
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * // Logged in user
   * const response = client.checkout.addStoreCredits({
   *   bearer_token: '7381273269536713689562374856',
   *   amount: 100
   * })
   *
   * // or guest user
   * const response = client.checkout.addStoreCredits({
   *   order_token: '7381273269536713689562374856',
   *   amount: 100
   * })
   * ```
   */
  public addStoreCredits(options: AddStoreCreditOptions): Observable<IOrder>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public addStoreCredits(token: IToken, params: AddStoreCredit): Observable<IOrder>;
  public addStoreCredits(...allArguments: any[]): Observable<IOrder> {
    const [tokenOrOptions, positionalParams] = allArguments;
    const { token, params } = squashAndPreparePositionalArguments(
      [tokenOrOptions, positionalParams],
      [],
    );

    return this.spreeResponse<IOrder>({
      method: 'POST',
      url: routes.checkoutAddStoreCreditsPath(),
      tokens: token,
      params: params,
    });
  }

  /**
   * Remove Store Credit payments if any applied. See [api docs](https://api.spreecommerce.org/docs/api-v2/b3A6MzE0Mjc1OQ-remove-store-credit).
   *
   * **Required token:** [Bearer token](../pages/tokens.html#bearer-token) or [Order token](../pages/tokens.html#order-token)
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * // Logged in user
   * const response = client.checkout.removeStoreCredits({
   *   bearer_token: '7381273269536713689562374856'
   * })
   *
   * // or guest user
   * const response = client.checkout.removeStoreCredits({
   *   order_token: '7381273269536713689562374856'
   * })
   * ```
   */
  public removeStoreCredits(options: RemoveStoreCreditsOptions): Observable<IOrder>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public removeStoreCredits(token: IToken, params?: IQuery): Observable<IOrder>;
  public removeStoreCredits(...allArguments: any[]): Observable<IOrder> {
    const [tokenOrOptions, positionalParams = {}] = allArguments;
    const { token, params } = squashAndPreparePositionalArguments(
      [tokenOrOptions, positionalParams],
      [],
    );

    return this.spreeResponse<IOrder>({
      method: 'POST',
      url: routes.checkoutRemoveStoreCreditsPath(),
      tokens: token,
      params: params,
    });
  }

  /**
   * Returns a list of available Payment Methods. See [api docs](https://api.spreecommerce.org/docs/api-v2/b3A6MzE0Mjc2MA-list-payment-methods).
   *
   * **Required token:** [Bearer token](../pages/tokens.html#bearer-token) or [Order token](../pages/tokens.html#order-token)
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * // Logged in user
   * const response = client.checkout.paymentMethods({
   *   bearer_token: '7381273269536713689562374856'
   * })
   *
   * // or guest user
   * const response = client.checkout.paymentMethods({
   *   order_token: '7381273269536713689562374856'
   * })
   * ```
   */
  public paymentMethods(options: PaymentMethodsOptions): Observable<IPaymentMethods>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public paymentMethods(token: IToken): Observable<IPaymentMethods>;
  public paymentMethods(...allArguments: any[]): Observable<IPaymentMethods> {
    const [tokenOrOptions] = allArguments;
    const { token, params } = squashAndPreparePositionalArguments([tokenOrOptions], []);

    return this.spreeResponse<IPaymentMethods>({
      method: 'GET',
      url: routes.checkoutPaymentMethodsPath(),
      tokens: token,
      params: params,
    });
  }

  /**
   * @hidden
   * @deprecated Use {@link shippingRates} instead.
   */
  public shippingMethods(
    token: IToken,
    params: IQuery = {},
  ): Observable<IShippingMethods> {
    return this.spreeResponse<IShippingMethods>({
      method: 'GET',
      url: routes.checkoutShippingMethodsPath(),
      tokens: token,
      params: params,
    });
  }

  /**
   * Returns a list of available Shipping Rates for Checkout. Shipping Rates are grouped against Shipments. Each checkout cna have multiple Shipments eg. some products are available in stock and will be send out instantly and some needs to be backordered. See [api docs](https://api.spreecommerce.org/docs/api-v2/ed60ec67b7d90-list-shipping-rates).
   *
   * **Required token:** [Bearer token](../pages/tokens.html#bearer-token) or [Order token](../pages/tokens.html#order-token)
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * // Logged in user
   * const response = client.checkout.shippingRates({
   *   bearer_token: '7381273269536713689562374856',
   *   include: 'shipping_rates,stock_location'
   * })
   *
   * // or guest user
   * const response = client.checkout.shippingRates({
   *   order_token: '7381273269536713689562374856',
   *   include: 'shipping_rates,stock_location'
   * })
   * ```
   */
  public shippingRates(options: ShippingRatesOptions): Observable<ShippingRates>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public shippingRates(token: IToken, params?: IQuery): Observable<ShippingRates>;
  public shippingRates(...allArguments: any[]): Observable<ShippingRates> {
    const [tokenOrOptions, positionalParams = {}] = allArguments;
    const { token, params } = squashAndPreparePositionalArguments(
      [tokenOrOptions, positionalParams],
      [],
    );

    return this.spreeResponse<ShippingRates>({
      method: 'GET',
      url: routes.checkoutShippingRatesPath(),
      tokens: token,
      params: params,
    });
  }

  /**
   * Selects a Shipping Method for Shipment(s). See [api docs](https://api.spreecommerce.org/docs/api-v2/b3A6MjY1NTc1NzY-selects-shipping-method-for-shipment-s).
   *
   * **Required token:** [Bearer token](../pages/tokens.html#bearer-token) or [Order token](../pages/tokens.html#order-token)
   *
   * **Options schema:**
   * ```ts
   * interface options {
   *   shipping_method_id: string
   *   shipment_id?: string
   * }
   * ```
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * const response = client.checkout.selectShippingMethod({
   *   bearer_token: '7381273269536713689562374856',
   *   shipping_method_id: '42'
   * })
   * ```
   */
  public selectShippingMethod(options: SelectShippingMethodOptions): Observable<IOrder>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public selectShippingMethod(
    token: IToken,
    params: SelectShippingMethod,
  ): Observable<IOrder>;
  public selectShippingMethod(...allArguments: any[]): Observable<IOrder> {
    const [tokenOrOptions, positionalParams] = allArguments;
    const { token, params } = squashAndPreparePositionalArguments(
      [tokenOrOptions, positionalParams],
      [],
    );

    return this.spreeResponse<IOrder>({
      method: 'PATCH',
      url: routes.checkoutSelectShippingMethodPath(),
      tokens: token,
      params: params,
    });
  }

  /**
   * Creates new Payment for the current checkout. See [api docs](https://api.spreecommerce.org/docs/api-v2/b3A6MjYyODA2NTY-create-new-payment).
   *
   * **Required token:** [Bearer token](../pages/tokens.html#bearer-token) or [Order token](../pages/tokens.html#order-token)
   *
   * **Options schema:**
   * ```ts
   * interface options {
   *   payment_method_id: string
   *   source_id?: string
   *   amount?: number
   *   source_attributes?: {
   *     gateway_payment_profile_id: string
   *     cc_type?: string
   *     last_digits?: string
   *     month?: string
   *     year?: string
   *     name: string
   *   }
   * }
   * ```
   *
   * **Success response schema:** [Success schema](../pages/response-schema.html#success-schema)
   *
   * **Failure response schema:** [Error schema](../pages/response-schema.html#error-schema)
   *
   * **Example:**
   * ```ts
   * // Logged in user
   *
   * // Create new credit card
   * const response = client.checkout.addPayment({
   *   bearer_token: '7381273269536713689562374856',
   *   payment_method_id: '1',
   *   source_attributes: {
   *     gateway_payment_profile_id: 'card_1JqvNB2eZvKYlo2C5OlqLV7S',
   *     cc_type: 'visa',
   *     last_digits: '1111',
   *     month: '10',
   *     year: '2026',
   *     name: 'John Snow'
   *   }
   * })
   *
   * // Use existing credit card
   * const response = client.checkout.addPayment({
   *   bearer_token: '7381273269536713689562374856',
   *   payment_method_id: '1',
   *   source_id: '1'
   * })
   *
   * // or guest user
   *
   * // Create new credit card
   * const response = client.checkout.addPayment({
   *   order_token: '7381273269536713689562374856',
   *   payment_method_id: '1',
   *   source_attributes: {
   *     gateway_payment_profile_id: 'card_1JqvNB2eZvKYlo2C5OlqLV7S',
   *     cc_type: 'visa',
   *     last_digits: '1111',
   *     month: '10',
   *     year: '2026',
   *     name: 'John Snow'
   *   }
   * })
   * ```
   */
  public addPayment(options: AddPaymentOptions): Observable<IOrder>;
  /**
   * @hidden
   * @deprecated Use the combined options signature instead.
   */
  public addPayment(token: IToken, addPaymentParams: AddPayment): Observable<IOrder>;
  public addPayment(...allArguments: any[]): Observable<IOrder> {
    const [tokenOrOptions, positionalParams] = allArguments;
    const { token, params } = squashAndPreparePositionalArguments(
      [tokenOrOptions, positionalParams],
      [],
    );

    return this.spreeResponse<IOrder>({
      method: 'POST',
      url: routes.checkoutAddPaymentPath(),
      tokens: token,
      params: params,
    });
  }

  /**
   * @hidden
   */
  public createStripeSession(
    options: CreateStripeSessionOptions,
  ): Observable<StripeCheckoutSessionSummary> {
    const { token, params } = squashAndPreparePositionalArguments([options], []);

    return this.spreeResponse<StripeCheckoutSessionSummary>({
      method: 'PATCH',
      url: routes.checkoutCreateStripeSessionPath(),
      tokens: token,
      params: params,
    });
  }
}
