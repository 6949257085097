import { inject, Injectable } from '@angular/core';
import { map, ReplaySubject, tap } from 'rxjs';
import { Store } from './store-serializer';
import { LocaleMap } from '../helpers/locale-map';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  private _activeLocale = '';
  private _activeCurrency = '';
  private _store!: Store;

  private readonly cookies = inject(SsrCookieService);
  private readonly store = new ReplaySubject<Store>(1);
  private readonly activeLocale = new ReplaySubject<string>(1);
  private readonly activeCurrency = new ReplaySubject<string>(1);

  readonly apiUrl = environment.apiUrl;
  readonly store$ = this.store.asObservable();
  readonly socials$ = this.store.pipe(map((store) => store.socialMedia));
  readonly activeLocale$ = this.activeLocale.pipe(
    tap((v) => {
      this._activeLocale = v;
      this._activeCurrency = LocaleMap[v].currency;
      this.activeCurrency.next(this._activeCurrency);
      this.persist('locale', v);
      this.persist('lang', v);
    }),
  );
  readonly activeCurrency$ = this.activeCurrency.pipe(
    tap((v) => {
      this.persist('currency', v);
      this._activeCurrency = v;
    }),
  );

  readonly availableLocales: ReadonlyArray<string> = Object.keys(LocaleMap);
  readonly contactPhone$ = this.store.pipe(map((store) => store.contactPhone));
  readonly address$ = this.store.pipe(map((store) => store.address));
  readonly customerSupportEmail$ = this.store.pipe(
    map((store) => store.customerSupportEmail),
  );

  setStore(store: Store): void {
    this._store = store;
    this.store.next(store);
  }

  getActiveCurrency(): string {
    return this._activeCurrency;
  }

  getActiveLocale(): string {
    return this._activeLocale;
  }

  setActiveLocale(locale: string): void {
    this.activeLocale.next(locale);
  }

  setActiveCurrency(currency: string): void {
    this.activeCurrency.next(currency);
  }

  private persist(key: string, value: string): void {
    this.cookies.set(key, value, { path: '/' });
  }
}
