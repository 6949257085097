import { FactoryProvider, InjectionToken, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ExtendedWindow } from '../types/window';

export const WINDOW = new InjectionToken('WindowToken');

export function windowFactory(platformId: object): ExtendedWindow | null {
  return isPlatformBrowser(platformId) ? (window as unknown as ExtendedWindow) : null;
}

export const WINDOW_PROVIDER: FactoryProvider = {
  provide: WINDOW,
  useFactory: windowFactory,
  deps: [PLATFORM_ID],
};
